// @ts-nocheck
import ButtonClose from '@components/ButtonClose';
import ColumnLayout from '@components/ColumnLayout';
import Logo from '@components/Logo';
import AuthUserContext from '@context/AuthUserContext';
import { calcLeadTimeByQuantity } from '@src/helpers/product';
import { format, addBusinessDays } from 'date-fns';
import Link from '@utility/Link';
import useCartState from '@utility/useCartState';
import { graphql, useStaticQuery } from 'gatsby';
import foreach from 'lodash.foreach';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

const NO_CANCEL_CODES = ['FINISHSAMPLES100']; //discount codes which should not be removed depending on the user_trade status.

const snipcartScript = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');

    Object.assign(script, {
      src: 'https://cdn.snipcart.com/themes/v3.3.3/default/snipcart.js',
      id: 'snipcart',
      defer: true,
    });
    script.dataset.apiKey = process.env.GATSBY_SNIPCART_APIKEY;
    script.addEventListener('load', () => {
      resolve();
    });
    script.addEventListener('error', (e) => {
      reject(e);
    });
    
    document.body.appendChild(script);
  });
};

const SnipCart = () => {
  const cartData = useStaticQuery(
    graphql`
      query {
        allSanityCart {
          edges {
            node {
              id
              heading
              subheading
              orderShipDateNote
              image {
                asset {
                  _id
                }
              }
            }
          }
        }
      }
    `,
  );
  const cartContent = cartData.allSanityCart.edges[0].node;
  const [status, setStatus] = useState('start');
  const [distinctItemsLength, setDistinctItemsLength] = useState(0);
  const { authUser } = useContext(AuthUserContext);
  const cartState = useCartState();
  const ref = useRef();

  const importSnipcartScript = () => {
    snipcartScript()
      .then(() => {
        setStatus('done');
      })
      .catch(() => {
        setStatus('error');
        //console.log('Snipcart failed to load');
      });
  };

  /**
   * Implemented for v3.0.15 of Snipcart.
   * Snipcart currently does not appear to have a way to remove promo codes out of the box..
   * Using their api to for the moment to allow removal of promo codes.
   * https://docs.snipcart.com/v3/sdk/api#removediscount
   */
  const removePromoCode = useCallback(async (code) => {
    try {
      await window.Snipcart.api.cart.removeDiscount(code);
    } catch (error) {
      //console.log(error);
    }
  }, []);

  useLayoutEffect(() => {
    const MutationObserver =
      window.MutationObserver || window.WebKitMutationObserver;
    const observer = new MutationObserver(() => {
      /**========================================================================
       *                           Discount Box addition/removal
       *========================================================================**/
      const discountBox = document.querySelector('.snipcart-discount-box');
      if (discountBox) {
        if (authUser?.user_trade) {
          discountBox.classList.add('hidden');
        } else {
          discountBox.classList.remove('hidden');
        }
      }

      addCheckoutFooterText();

      /**========================================================================
       *                           Initializing Promo Codes
       *========================================================================**/
      const elements = document.querySelectorAll(
        '.snipcart-summary-fees__discount-name',
      );
      const discounts = (
        Snipcart.store.getState().cart.discounts?.items || []
      ).map((item) =>
        item.code ? { code: item.code, name: item.name } : null,
      );

      if (elements && elements.length > 0) {
        foreach(elements, (element) => {
          const code = element.innerHTML;

          const filteredDiscount = discounts.find(
            (discount) => discount?.code === code || discount?.name === code,
          );

          if (!!filteredDiscount) {
            if (
              authUser?.user_trade &&
              !NO_CANCEL_CODES.includes(filteredDiscount.code)
            ) {
              removePromoCode(filteredDiscount.code);
            } else {
              element.addEventListener('click', () => {
                removePromoCode(filteredDiscount.code);
              });
            }
          } else {
            element.classList.add('no-button');
          }
        });
      }

      /**------------------------------------------------------------------------
       *                           Correcting Inner HTML
       *------------------------------------------------------------------------**/
      const discountAmountElements = document.querySelectorAll(
        '.snipcart-summary-fees__discount-amount-saved',
      );
      if (discountAmountElements && discountAmountElements.length > 0) {
        foreach(discountAmountElements, (discountAmountElement) => {
          if (discountAmountElement.innerHTML === ' -') {
            discountAmountElement.innerHTML = ' -$0.00';
          }
        });
      }
    });

    if (status === 'start') {
      setStatus('ready');
    }

    if (status === 'done') {
      Snipcart.events.on('page.validating', function (ev, data) {
        if (data.phone.length !== 10) {
          ev.addError(
            'phone',
            'Please enter a US or Canadian phone number without any symbols',
          );
        }
      });

      Snipcart.events.on('discount.applied', (discount) => {
        setTimeout(() => {
          document.querySelector('.snipcart-discount-box__cancel')?.click();
        }, 100);
      });

      Snipcart.events.on('cart.confirmed', (cartConfirmResponse) => {
        gtag('event', 'successful_purchase', {
          send_to: '7FU0COzI35AZENGQ66U9',
          value: 1.0,
          currency: 'USD',
        });
      });

      Snipcart.events.on('item.removed', (cartConfirmResponse) => {
        onItemsChange()
      });

      Snipcart.events.on('item.added', (cartConfirmResponse) => {
        onItemsChange()
      });

      observer.observe(document, {
        subtree: true,
        childList: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [status, authUser?.user_trade, removePromoCode]);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      if (status === 'done' && cartState?.hasItems) {
        const cartProducts = Snipcart.store
          .getState()
          .cart.items.items.map(
            ({ uniqueId, id, url, customFields, price, quantity }) => ({
              uniqueId,
              id,
              url:
                process.env.GATSBY_DEVELOPMENT_MODE === 'true'
                  ? `https://rbw-qa.netlify.app${new URL(url).pathname}`
                  : url,
              customFields,
              price,
              quantity
            }),
          );

          onItemsChange();

        if (cartProducts.length > 0) {
          //* swapping cart items to match user trade status
          await Promise.all(
            cartProducts.map(async (product) => {
              const response = await fetch(product.url, {
                signal: controller.signal,
              });
              const htmlText = await response.text();

              // Initialize the DOM parser
              const parser = new DOMParser();

              const id = authUser?.user_trade
                ? !product.id.includes('discount')
                  ? `${product.id}-discount`
                  : product.id
                : product.id.replace(/-discount$/, '');

              // Parse the text
              const doc = parser.parseFromString(htmlText, 'text/html');
              const correctPrice = JSON.parse(
                doc.getElementById('js-project-card').innerHTML,
              )[authUser?.user_trade ? 'discount_price' : 'price'];

              product.customFields[1].value = JSON.stringify(
                !!authUser?.user_trade,
              );
              
              let productCustomFields = updateShipDateTextInCustomFields([...product.customFields], product.quantity)
              return Snipcart.api.cart.items.update({
                uniqueId: product.uniqueId,
                id,
                price: correctPrice,
                customFields: productCustomFields,
                url: product.url,
              });
            }),
          );
        }
      }
    })();

    return () => controller?.abort();
  }, [authUser?.user_trade, cartState?.hasItems, status, cartState?.quantity]);

  if (status === 'ready' && ref.current) {
    importSnipcartScript();
  }

  const getShipDateText = (from, to, quantity, shipDateOverride) => {
    let shipdateTextTemp = ''
    if (quantity > 200) {
      shipdateTextTemp = 'Please contact RBW for lead time info'
    }
    else if (shipDateOverride) shipdateTextTemp = shipDateOverride;
    else shipdateTextTemp = `Available to ship between ${formatDate(from)} - ${formatDate(to)}`;
    return shipdateTextTemp;
  }

  const formatDate = (date) => {
    return format(date, 'MMM d');
  };

  const updateShipDateTextInCustomFields = (customFields, quantity) => {
    let shipDateOverride = customFields.find((e) => e.name === 'shipDateOverride')?.value;
    let minLeadTime = customFields.find((e) => e.name === 'minLeadTime')?.value;
    let leadTimeRange = {};

    if (!shipDateOverride && !minLeadTime) {
      return customFields;
    }

    if (customFields.findIndex((e) => e.name === 'Accessory') !== -1) {
      leadTimeRange = {
        min: parseInt(customFields.find((e) => e.name === 'minLeadTime')?.value),
        max: parseInt(customFields.find((e) => e.name === 'maxLeadTime')?.value)
      }
    } else {
      let d = {
        minLeadTime: parseInt(customFields.find((e) => e.name === 'minLeadTime').value) || 0,
        shipDateOverride: shipDateOverride,
        maxLeadTime: parseInt(customFields.find((e) => e.name === 'maxLeadTime').value) || 0,
        leadTime10: parseInt(customFields.find((e) => e.name === 'leadTime10').value) || 0,
        leadTime25: parseInt(customFields.find((e) => e.name === 'leadTime25').value) || 0,
        leadTime50: parseInt(customFields.find((e) => e.name === 'leadTime50').value) || 0,
        leadTime100: parseInt(customFields.find((e) => e.name === 'leadTime100').value) || 0,
        leadTime200: parseInt(customFields.find((e) => e.name === 'leadTime200').value) || 0,
      }
  
      leadTimeRange = calcLeadTimeByQuantity(Number.isNaN(quantity) ? 0 : quantity, d);
    }

    let addedLeadTimeDays = parseInt(customFields.find((e) => e.name === 'addedLeadTimeDays')?.value) || 0
    let updatedCustomFields = customFields.slice();
    let fromDate = addBusinessDays(new Date(), leadTimeRange.min + addedLeadTimeDays)
    let toDate = addBusinessDays(new Date(), leadTimeRange.max + addedLeadTimeDays)
    let shipdateText = getShipDateText(fromDate, toDate, quantity, shipDateOverride);
    
    let estIndex = updatedCustomFields.findIndex(a => a.name.includes('Estimated'));
    if (estIndex)
      updatedCustomFields[estIndex + 1].name = shipdateText;
    return updatedCustomFields;
  }

  const onItemsChange = () => { //Check if no of distict items > 2, then add the orderShipDateNote
    const cartProducts = Snipcart.store
          .getState()
          .cart.items.items.map(
            ({ uniqueId, id, url, customFields, price }) => ({
              uniqueId,
              id,
              url:
                process.env.GATSBY_DEVELOPMENT_MODE === 'true'
                  ? `https://rbw-qa.netlify.app${new URL(url).pathname}`
                  : url,
              customFields,
              price,
            }),
          );

          const distinctItemIds = cartProducts.map(product => product.id)
          .filter((value, index, self) => self.indexOf(value) === index);

          setDistinctItemsLength(distinctItemIds.length);

          addCheckoutFooterText(distinctItemIds.length)
  }

  const addCheckoutFooterText = (itemLength) => {
    // Select the footer element
    const footer = document.querySelector('.snipcart-cart__footer-buttons');

    let length = itemLength !== null || itemLength !== undefined ? itemLength : distinctItemsLength;

    // Check if the footer element exists
    if (footer) {
        const button = footer.querySelector('button.snipcart-button-primary');

        // Check if the button exists and its title is "Checkout"
        if (button && button.textContent.trim() === 'Checkout') {
            // Check if the <p> tag is already added
            let paragraph = footer.querySelector('p');

            // If <p> tag doesn't exist, create and append it
            if (!paragraph) {
                paragraph = document.createElement('p');
                paragraph.classList.add('mt-4', '!text-mono-500', '!type-sans-030');
                paragraph.textContent = cartContent.orderShipDateNote;
                button.parentNode.insertBefore(paragraph, button.nextSibling);
            }
        } else {
            // Select and remove the <p> tag if the button title is not "Checkout"
            const paragraph = footer.querySelector('p');
            if (paragraph) {
                paragraph.parentNode.removeChild(paragraph);
            }
        }
    }
  }

  return (
    <>
      {(status === 'ready' || status === 'done') && (
        <div
          hidden
          id="snipcart"
          data-api-key={process.env.GATSBY_SNIPCART_APIKEY}
          data-config-add-product-behavior="none"
          ref={ref}
        >
          {/* * CART HEADER */}
          <cart-header
            title=""
            showItemsCount="false"
            showAccountMenu="false"
            showSummary="false"
            showAccountLink="false"
            backButtonTitle=""
          >
            <div className="root">
              <div className="wrapper">
                <div className="snipcart-cart-header">
                  <Link to="/" styled={false} title="RBW" aria-label="RBW Logo">
                    <Logo />
                  </Link>
                  <close-cart-action class="snipcart-cart-header__close-button snipcart-modal__close">
                    <ButtonClose aria-label="Close"/>
                  </close-cart-action>
                </div>
              </div>
            </div>
          </cart-header>

          {/* * EMPTY CART */}
          <empty-cart>
            <div className="root">
              <section className="snipcart-empty-cart">
                <ColumnLayout
                  heading={cartContent.heading}
                  subheading={cartContent.subheading}
                  image={cartContent?.image}
                >
                  <Link
                    to="/type"
                    className="btn btn-default btn-black"
                    styled={false}
                    aria-label="Browse Products"
                  >
                    Browse Products
                  </Link>
                </ColumnLayout>
              </section>
            </div>
          </empty-cart>

          {/* * BILLING FORM */}
          <billing>
            <div class="snipcart__box">
              <div class="snipcart__box--header">
                <div class="snipcart__box--title">
                  <h1 class="snipcart__font--subtitle">Billing</h1>
                </div>
              </div>

              <snipcart-form-error></snipcart-form-error>

              <overridable name="billing" section="top"></overridable>

              <fieldset class="snipcart-form__set">
                <div class="snipcart-form__field">
                  <snipcart-label class="snipcart__font--tiny" for="name">
                    Full Name
                  </snipcart-label>

                  <snipcart-input
                    name="name"
                    pattern="^[a-zA-Z]([-']?[a-zA-Z]+)*( [a-zA-Z]([-']?[a-zA-Z]+)*)+$"
                    title="Please enter your full name without symbols"
                  ></snipcart-input>
                  <snipcart-field-error name="name"></snipcart-field-error>
                </div>

                <div class="snipcart-form__field">
                  <snipcart-label class="snipcart__font--tiny" for="email">
                    Email
                  </snipcart-label>

                  <snipcart-input name="email"></snipcart-input>
                  <snipcart-field-error name="email"></snipcart-field-error>
                </div>
              </fieldset>

              <address-fields type="billing"></address-fields>

              <hr class="snipcart-form__separator" v-if="shippingEnabled" />

              <fieldset
                class="snipcart-form__set snipcart-form__set--different-shipping"
                v-if="shippingEnabled && !hasOnlyNonShippableGoods"
              >
                <div class="snipcart-form__field">
                  <div class="snipcart-form__field-checkbox">
                    <snipcart-checkbox name="useDifferentShippingAddress" />

                    <snipcart-label
                      class="snipcart__font--tiny snipcart-form__label--checkbox"
                      for="useDifferentShippingAddress"
                    >
                      Use different shipping address
                    </snipcart-label>
                  </div>
                </div>
              </fieldset>
              <overridable name="billing" section="bottom"></overridable>

              <div class="snipcart-form__footer">
                <snipcart-submit label="Continue to Shipping"></snipcart-submit>
              </div>
            </div>
          </billing>

          {/* * SHIPPING ADDRESS FORM */}
          <shipping-address>
            <div className="root">
              <div class="snipcart__box">
                <div class="snipcart__box--header">
                  <div class="snipcart__box--title">
                    <h1 class="snipcart__font--subtitle">Shipping</h1>
                  </div>
                </div>

                <div class="snipcart-shipping-address--readonly">
                  <div class="snipcart-shipping-address__header--readonly">
                    <h3 class="snipcart-shipping-address__subtitle snipcart__font--bold"></h3>
                  </div>
                </div>

                <overridable
                  name="shipping-address"
                  section="top"
                ></overridable>

                <snipcart-label for="name">Full Name</snipcart-label>
                <snipcart-input
                  name="name"
                  pattern="^[a-zA-Z]([-']?[a-zA-Z]+)*( [a-zA-Z]([-']?[a-zA-Z]+)*)+$"
                  title="Please enter your full name without symbols"
                ></snipcart-input>
                <snipcart-field-error name="name"></snipcart-field-error>

                <address-fields type="shipping"></address-fields>

                <overridable
                  name="shipping-address"
                  section="bottom"
                ></overridable>
                <div class="snipcart-form__footer">
                  <snipcart-submit label="Use This Address"></snipcart-submit>
                </div>
              </div>
            </div>
          </shipping-address>
          <address-fields type="shipping">
            <div className="root">
              <overridable name="address-fields" section="top"></overridable>
              <fieldset class="snipcart-form__set">
                <div class="snipcart-form__row">
                  <div class="snipcart-form__field snipcart-form__cell--large">
                    <snipcart-label class="snipcart__font--tiny" for="address1">
                      Street Address
                    </snipcart-label>
                    <snipcart-input
                      name="address1"
                      pattern="(?!.*(?:(.*([Pp][-.\s]*[Oo][-.\s]+)))).*"
                      title="We currently do not offer shipping to a PO Box, please enter a physical address"
                    ></snipcart-input>
                    <snipcart-field-error name="address1"></snipcart-field-error>
                  </div>

                  <div class="snipcart-form__field snipcart-form__cell--tidy">
                    <snipcart-label class="snipcart__font--tiny" for="address2">
                      Apt/Suite
                    </snipcart-label>
                    <snipcart-input name="address2"></snipcart-input>
                    <snipcart-field-error name="address2"></snipcart-field-error>
                  </div>
                </div>

                <div class="snipcart-form__field">
                  <snipcart-label class="snipcart__font--tiny" for="city">
                    City
                  </snipcart-label>
                  <snipcart-input name="city"></snipcart-input>
                  <snipcart-field-error name="city"></snipcart-field-error>
                </div>

                <div class="snipcart-form__field">
                  <snipcart-label class="snipcart__font--tiny" for="country">
                    Country
                  </snipcart-label>
                  <snipcart-typeahead
                    type="dropdown"
                    name="country"
                    autocomplete="country"
                  ></snipcart-typeahead>
                  <snipcart-field-error name="country"></snipcart-field-error>
                </div>

                <div class="snipcart-form__row">
                  <div class="snipcart-form__field snipcart-form__cell--large">
                    <snipcart-label class="snipcart__font--tiny" for="province">
                      Province/State
                    </snipcart-label>
                    <snipcart-typeahead
                      type="dropdown"
                      name="province"
                      autocomplete="province state"
                    ></snipcart-typeahead>
                    <snipcart-field-error name="province"></snipcart-field-error>
                  </div>

                  <div class="snipcart-form__field snipcart-form__cell--tidy">
                    <snipcart-label
                      class="snipcart__font--tiny"
                      for="postalCode"
                    >
                      Postal/Zip Code
                    </snipcart-label>
                    <snipcart-input name="postalCode"></snipcart-input>
                    <snipcart-field-error name="postalCode"></snipcart-field-error>
                  </div>
                </div>
              </fieldset>
              <overridable name="address-fields" section="bottom"></overridable>
            </div>
          </address-fields>

          {/* * PHONE NUMBER ADDITION */}
          <address-fields section="top">
            <div className="snipcart-form__field">
              <snipcart-label for="phone">Phone number</snipcart-label>

              <snipcart-input
                name="phone"
                type="tel"
                pattern="^[-\d]\d*$"
                title="Enter phone number without any symbols"
                required
              ></snipcart-input>
              <snipcart-field-error name="phone"></snipcart-field-error>
            </div>
          </address-fields>

          {/* * CART ITEMS */}
          <item-line tag="" item="" showDescription="">
            <div className="snipcart-item-line">
              <flash-message
                type="info"
                icon="box"
                v-if="stockLimitReached"
                {...{ ':title': "$localize('errors.quantity_revised')" }}
              ></flash-message>
              <flash-message
                type="error"
                icon="box"
                v-if="outOfStock"
                {...{ ':title': "$localize('errors.quantity_out_of_stock')" }}
              ></flash-message>

              <div class="snipcart-item-line__container">
                <figure class="snipcart-item-line__media" v-if="showLargeImage">
                  <a {...{ ':href': 'item.url' }}>
                    <item-image class="snipcart-item-line__image"></item-image>
                  </a>
                </figure>

                <div class="snipcart-item-line__product">
                  <div class="snipcart-item-line__header">
                    <figure
                      class="snipcart-item-line__media snipcart-item-line__media--small"
                      v-if="showSmallImage"
                    >
                      <a {...{ ':href': 'item.url' }}>
                        <item-image class="snipcart-item-line__image"></item-image>
                      </a>
                    </figure>

                    <a {...{ ':href': 'item.url' }}>
                      <h2 class="snipcart-item-line__title snipcart__font--xlarge snipcart__font--secondary snipcart__font--black">
                        {'{{ item.name }}'}
                      </h2>
                    </a>

                    <button-icon
                      icon="trash"
                      label="item.remove_item"
                      styling="danger"
                      {...{
                        'v-on:click': 'removeItem',
                      }}
                    ></button-icon>
                  </div>

                  <div class="snipcart-item-line__content">
                    <div class="snipcart-item-line__body">
                      <div class="snipcart-item-line__info">
                        <a {...{ ':href': 'item.url' }}>
                          <item-description v-if="showDescription"></item-description>
                        </a>
                      </div>
                      <div class="snipcart-item-line__variants">
                        <div>
                          <item-custom-fields v-if="!adding"></item-custom-fields>
                        </div>

                        <item-quantity
                          class="snipcart-item-line__quantity"
                          v-if="!adding && !isSubscribable"
                          {...{ ':disabled': 'outOfStock || isSubscribable' }}
                        ></item-quantity>
                        <div class="snipcart-item-quantity__unit-price">
                          <p
                            class="snipcart-item-quantity__unit-price-label"
                            v-if="item.customFields[1].value==='true'"
                          >
                            Trade Price
                          </p>
                          <p
                            class="snipcart-item-quantity__unit-price-label"
                            v-if="item.customFields[1].value!=='true'"
                          >
                            Price
                          </p>
                          <p
                            class="snipcart-item-quantity__unit-price-discounted"
                            v-if="item.customFields[1].value!=='true' || +item.customFields[0].value===item.unitPrice"
                          >
                            {
                              "{{(+item.unitPrice).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2}).replace(/\\.00$/g, '')}}"
                            }
                          </p>
                          <p
                            class="snipcart-item-quantity__unit-price-discounted !text-green"
                            v-if="item.customFields[1].value==='true' && +item.customFields[0].value!==item.unitPrice"
                          >
                            {
                              "{{(+item.unitPrice).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2}).replace(/\\.00$/g, '')}}"
                            }
                          </p>
                        </div>

                        <div
                          class="snipcart-item-quantity__unit-price-container"
                          v-if="+item.customFields[0].value!==item.unitPrice"
                        >
                          <p
                            class="snipcart-item-quantity__unit-price-price"
                            v-if="+item.customFields[0].value!==item.unitPrice"
                          >
                            {
                              "{{(+item.customFields[0].value).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2}).replace(/\\.00$/g, '')}}"
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </item-line>

          <shipping-method section="bottom">
            <p
              class="snipcart-shipping__disclaimer"
              {...{ 'v-if': 'cart.items.items.length > 1' }}
            >
              Fixtures may arrive in partial shipments
            </p>
          </shipping-method>

          <shipping-completed context="">
            <div className="root">
              <div class="snipcart__box snipcart-shipping-completed">
                <div class="snipcart-shipping-completed__header snipcart__box--header">
                  <div class="snipcart__box--title">
                    <div
                      class="snipcart__box--badge snipcart__font--bold snipcart__font--secondary"
                      v-if="checkout"
                    >
                      <icon name="checkmark" class="snipcart__icon"></icon>
                    </div>
                    <h1 class="snipcart-shipping-completed__title snipcart__font--subtitle">
                      <icon
                        name="box"
                        class="snipcart__icon--left"
                        v-if="!checkout"
                      ></icon>
                      {"{{ $localize('shipping.title') }}"}
                    </h1>
                  </div>
                  <button-link
                    v-if="checkout"
                    label="actions.edit"
                    {...{ '@click': 'edit' }}
                  ></button-link>
                </div>

                <div class="snipcart-checkout-step__cols snipcart__font--std">
                  <div class="snipcart-checkout-step__col">
                    <div class="snipcart-checkout-step__icon snipcart-shipping-completed__step-icon">
                      <icon name="location"></icon>
                    </div>
                    <div class="snipcart__address__display">
                      <h3 class="snipcart-checkout-step__title snipcart-shipping-completed__step-title snipcart__font--secondary snipcart__font--bold">
                        {"{{ $localize('shipping.address') }}"}
                      </h3>
                      <span class="snipcart-shipping-completed__information snipcart__font--std">
                        {'{{ cart.shippingAddress | fullAddress }}'}
                      </span>
                    </div>
                  </div>

                  <div class="snipcart-checkout-step__col">
                    <div class="snipcart-checkout-step__icon snipcart-shipping-completed__step-icon">
                      <icon name="box"></icon>
                    </div>
                    <div>
                      <h3 class="snipcart-checkout-step__title snipcart-shipping-completed__step-title snipcart__font--secondary snipcart__font--bold">
                        {"{{ $localize('shipping.method') }}"}
                      </h3>
                      <span class="snipcart-shipping-completed__information">
                        {
                          '{{ localizationId ? $localize(localizationId) : shippingDetails.method }}'
                        }
                      </span>
                    </div>
                  </div>
                </div>

                <p
                  class="snipcart-shipping__disclaimer"
                  {...{ 'v-if': 'cart.items.items.length > 1' }}
                >
                  Fixtures may arrive in partial shipments
                </p>
              </div>
            </div>
          </shipping-completed>

          <payment section="top">
            <div>
              <p class="snipcart-privacy-policy">
                <snipcart-checkbox
                  name="Agree-to-Terms-and-Condition"
                  required
                  title="Please check this box to proceed"
                />
                <snipcart-label for="Agree-to-Terms-and-Condition">
                  I have read and agree to the{' '}
                  <a href="/terms-&-conditions">Terms & Conditions</a>
                </snipcart-label>
              </p>
            </div>
          </payment>

          <cart-summary-item>
            <div className="root">
              <slot v-bind="item">
                <li class="snipcart-cart-summary-item">
                  <span class="snipcart-cart-summary-item__name snipcart__font--slim">
                    {'{{ item.name }}'}
                  </span>

                  <span class="snipcart-cart-summary-item__quantity snipcart__font--slim">
                    {
                      "{{ $localize('cart_summary.quantity') }}{{ item.quantity }}"
                    }
                  </span>

                  <span class="snipcart-cart-summary-item__price snipcart__font--slim">
                    {
                      '{{(+item.totalPrice).toLocaleString("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2, minimumFractionDigits: 2}).replace(/\\.00$/g, "")}}'
                    }
                  </span>
                </li>
              </slot>
            </div>
          </cart-summary-item>

          <cart-summary-expanded-item>
            <div className="root">
              <li class="snipcart-cart-summary-expanded-item">
                <div>
                  <span class="snipcart-cart-summary-expanded-item__name snipcart__font--secondary snipcart__font--regular">
                    {'{{ item.name }}'}
                  </span>
                  <ul class="snipcart-cart-summary-expanded-item__custom-fields">
                    <li
                      v-for="(customField, i) in visibleCustomFields"
                      {...{ ':key': 'i' }}
                    >
                      {'{{ customField.name }}'}: {'{{ customField.value }}'}
                    </li>
                  </ul>
                </div>
                <div class="snipcart-cart-summary-expanded-item--secondary snipcart-cart-summary-expanded-item__quantity">
                  {
                    "{{ $localize('item.quantity_short') }}: {{ item.quantity }}"
                  }
                </div>
                <div class="snipcart-cart-summary-expanded-item--secondary snipcart-cart-summary-expanded-item__price snipcart__font--secondary snipcart__font--bold">
                  {
                    '{{(+item.totalPrice).toLocaleString("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2, minimumFractionDigits: 2}).replace(/\\.00$/g, "")}}'
                  }
                </div>
              </li>
            </div>
          </cart-summary-expanded-item>
        </div>
      )}
    </>
  );
};

export default SnipCart;
